body {
	background-color: #0d0d0d;
}

body, body * {
  margin: 0;
  box-sizing: border-box;
  /*font-family: 'Roboto', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  /*font-weight:300;*/
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

/*body div {
	box-sizing: border-box;
}*/

h1, h2, h3, h4, h5, h6, p, a {
	text-decoration: none;
	font-family: 'Roboto', 'Helvetica Neue', sans-serif;
	font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	color:#eee;
}

h1 { 
	font-size: 4rem;
	line-height: 8.5rem;
	letter-spacing: .5rem;
}
h2 { 
	text-align: center;
	font-size: 3rem;
	line-height: 4rem;
	color: #ccc;
}
h3 { 
	text-align: left;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 600;
}
br {
	line-height: 2rem;
}

/* SCREEN WIDTHS */

@media (max-width: 700px){
	h1 {
		font-size: 3rem;
		letter-spacing: .5rem;
	}
}

@media (max-width: 600px){
	h1 {
		font-size: 2.5rem;
		letter-spacing: .4rem;
	}
}

@media (max-width: 500px){
	h1 {
		font-size: 2.5rem;
		letter-spacing: .4rem;
		line-height: 6rem;
	}
}

@media (max-width: 450px){
	h1 {
		font-size: 2.5rem;
		letter-spacing: .25rem;
	}
}
@media (max-width: 400px){
	h1 {
		font-size: 2rem;
		letter-spacing: .25rem;
	}
}