.back {
	/*bottom: 0; left: 0;*/
	position: fixed;
	z-index: -100;
	margin: 0; padding: 0;
	height: 100vh;
	width: 100vw;
	background-color: #0d0d0d;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.header {
	position: relative;
	display: flex;
	justify-content: center;
	top: 0; left: 0;
	padding: .55rem;
	width: 100vw;
	height: fit-content;
	margin-bottom: 3rem;
}
.logo-header {
	position: relative;
	width: auto;
	height:10rem;
	max-height:100vh;
	text-align: center;
	margin: 0 2rem;
	vertical-align: middle;
}
.logo-header svg {
	stroke:#b00;
}

@media (max-width: 500px) {
	.logo-header {
		margin: 0 1rem;
		height: 7rem;
	}
}

.nav {
	position: relative;
	display: block;
	float: right;
	height: 3em;
	width: 3em;
	cursor: pointer;
}
.centered {
	margin: 0 auto;
	width: fit-content;
}
.welcome {
	/*margin: 2rem 2rem 3rem 2rem;*/
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	grid-row-gap: 7rem;
	grid-template-rows: auto;
	max-width: 1200px;
	margin: 0 auto;
}
.welcome h3 {
	/*padding-left: 2.5rem;*/
	width: fit-content;
}
.welcome .imgdiv {
	display: flex;
	justify-content: center;
	align-items: center;
	height: fit-content;
}
.welcome img {
	width: 98%;	
}
.img1 img{ height: 21rem; }
.img2 img{ height: 18rem; }
.img3 img{ height: 18rem; }

.redtxt {
	color:#b00;
}

.welcome .w1 { order: 1; }
.welcome .img1 { order: 2; }
.welcome .img2 { order: 3; }
.welcome .w2 { order: 4; }
.welcome .w3 { order: 5; }
.welcome .img3 { order: 6; }
.welcome .w4 { order: 7; }

.welcome .w4 {
	grid-column: span 2;
}
.w4 h3 {
	width: auto;
	text-align: center;
}

@media (max-width: 1000px){
	.welcome h3 {
		font-size: 1.8rem;
		line-height: 2.3rem;
	}
	.img1 img { height: 16.1rem; }
	.img2 img, .img3 img { height: 13.8rem; }
}
@media (max-width: 900px){
	.welcome h3 {
		/*padding-left: 1rem;*/
	}
}
@media (max-width: 800px){
	.welcome {
		grid-row-gap: 5rem;
	}
	.welcome h3 {
		font-size: 1.5rem;
		line-height: 2rem;
	}
	.img1 img { height: 14rem; }
	.img2 img, .img3 img { height: 12rem; }
}
@media (max-width: 650px){
	.welcome {
		grid-template-columns: 1fr;
		grid-row-gap: 3rem;
	}
	.welcome h3 {
		/*padding-left: 4rem;*/
	}
	.img1 img, .img2 img, .img3 img {
		height: auto;
	}
	.welcome .img2 { order: 4; }
	.welcome .w2 { order: 3; }
	
	.welcome .w4 {
		grid-column: span 1;
	}
}